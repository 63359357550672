import request from '@/utils/request';

export function ge(data) {
  return request({
    url: '/api/insure/apply/stepFirst',
    method: 'post',
    data,
  });
}


export function stepSecond(data) {
  return request({
    url: '/api/insure/apply/stepSecond',
    method: 'post',
    data,
  });
}

export function stepThird(data) {
  return request({
    url: '/api/insure/apply/stepThird',
    method: 'post',
    data,
  });
}

export function dictDetail(params) {
  return request({
    url: '/api/dictDetail',
    method: 'get',
    params,
  });
}

export function stepForth(data) {
  return request({
    url: '/api/insure/apply/stepForth',
    method: 'post',
    data,
  });
}

export function detail(params) {
  return request({
    url: '/api/insure/apply/detail',
    method: 'get',
    params,
  });
}

export function delUploadFile(data) {
  return request({
    url: '/api/insure/apply/delUploadFile',
    method: 'post',
    data,
  });
}

export function getList(params) {
  const type = params.type === '全部' ? null : params.type
  return request({
    url: '/api/prepay/list',
    method: 'get',
    params: {
      ...params,
      date: [],
      type: type
    },
  });
}

export function getRemainingAmount() {
  return request({
    url: '/api/prepay/getRemainingAmount',
    method: 'get',
  });
}

export function getRemainingAmounts(encrypted) {
  return request({
    url: `/api/insurePay/prepay/getRemainingAmount/${encrypted}`,
    method: 'get',
  });
}

export function getPayeeInfo() {
  return request({
    url: '/api/prepay/getPayeeInfo',
    method: 'get',
  });
}

export function savePrepay(data) {
  return request({
    url: '/api/prepay/savePrepay',
    method: 'post',
    data
  });
}

export default { getList, getRemainingAmount, getPayeeInfo,getRemainingAmounts, savePrepay };
